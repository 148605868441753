<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between">
          <div class="col-md-6">
            <div class="heading">
              <h2>Audit Log Viewer</h2>
              <h3>PBE Management Dashboard</h3>
            </div>
          </div>
          <div class="col-md-6 text-sm-end mt-md-0 mt-2">
            <a class="btn btn_primary ml_24 d-none" href="#"
              >Export Audit Log</a
            >
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="table_card audit_list">
              <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                <div class="dataTables_length" id="datatable_length">
                  <label
                    >Show
                    <select
                      name="datatable_length"
                      aria-controls="datatable"
                      class=""
                      v-model.number="itemsPerPage"
                      @change="getProductsBysearch"
                    >
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>500</option>
                    </select>
                    entries</label
                  >
                </div>
                <div id="datatable_filter" class="dataTables_filter">
                  <label
                    ><input
                      type="search"
                      class=""
                      placeholder="Search"
                      aria-controls="datatable"
                      v-model="listConfig.search_string" 
                      v-on:keyup.enter="getProductsBysearch"
                  />
                  <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getProductsBysearch" class="search_icon cntr_search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>
                    </a>
                    <a href="javascript:void(0)" v-if="listConfig.search_string" @click="listConfig.search_string = null; getProductsBysearch()"  class="crossicon cntr_cross">
                        X
                    </a>
                  </label>
                  <a href="javascript:" class="comman_icons xl_download" v-if="auditsCheckbox.length > 0 && bulk_delete_permission" title="Delete Audits" @click="deleteBulkAudits">
                    <span class="ml_10">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 13.503 14.94" style="margin-left: 10px; margin-top: -3px;"><path data-name="Path 108" d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5" transform="translate(-3 -2)" style="fill: none; stroke: rgb(169, 169, 169); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px;"></path></svg>
                        <b>Delete</b>
                    </span>
                  </a>
                </div>
              </div>
              <div class="table-responsive table_audit_log">
                <table class="table table-bordered table dataTable no-footer">
                  <thead>
                    <tr>
                      <th class="sort sml_check" style="min-width: 10px"  v-if="data_length > 0 && bulk_delete_permission">
                        <div class="form_check_box d-flex align-items-center mt-1 ">
                            <input type="checkbox" v-model="selectAll" aria-label="..."
                                id="flexCheckDefault" class="form-check-input ms-0" />
                            <label for="flexCheckDefault"></label>
                        </div>
                      </th>
                      <th class="sort">
                        <SortIcons
                          :lable="'Date'"
                          :listConfig="listConfig"
                          :sortBy="'created_at'"
                          @sorting="sorting($event)"
                        />
                      </th>
                      <th>User</th>
                      <th>Source IP</th>
                      <th>Area</th>
                      <th>Event Type</th>
                      <th>Event Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(audit, indx) in audits" :key="indx">
                      <td style="min-width: 10px"  v-if="data_length > 0 && bulk_delete_permission">
                        <div class="form_check_box d-flex align-items-center">
                          <input type="checkbox" v-model="auditsCheckbox" aria-label="..."
                            :id="'flexCheckDefault' + indx" class="form-check-input ms-0" :value="audit.id" />
                          <label :for="'flexCheckDefault' + indx" class=""></label>
                        </div>
                      </td>
                      <td>
                        <p class="audit_date_date">
                          {{ audit.date }}
                        </p>
                      </td>
                      <td>
                        <div class="d-flex audit_user">
                          <div class="flex-shrink-0">
                            <img :src="audit.avatar" alt="img" />
                          </div>
                          <div class="flex-grow-1 ms-3">
                            {{ audit.user_name }}
                            <p>{{ audit.role_name }}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        {{ audit.source_ip }}
                      </td>
                      <td>
                        {{ audit.area }}
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <div class="message_box">
                              <p>{{ audit.event }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <div class="message_box">
                              <p v-if="audit.event_message.length > 100" v-tooltip="audit.event_message" :v-html="audit.event_message">{{ audit.event_message }}</p> 
                              <p  v-else :v-html="audit.event_message">{{ audit.event_message }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="data_length == 0">
                      <td colspan="7" class="text-center">No Records found</td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
              <p class="float-lg-start text-sm-center">{{ positionText }}</p>
              <VPagination
                v-if="data_length"
                class="dataTables_paginate"
                :totalPages="pages.length"
                :perPage="itemsPerPage"
                :currentPage.sync="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import commonFunction  from '../../mixin/commonFunction';
import ConfirmDialogue from "@/components/ConfirmDialogue";
export default {
  name: "audit_log_viewer",
  mixins: [commonFunction],
  data() {
    return {
      audits: [],
      isLoader: false,
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
      },
      data_length: 0,
      auditsCheckbox: [],
      auditsCheckboxArr: {
        checkboxes: [],
      },
      bulk_delete_permission:false
    };
  },
  components: {
    VPagination,
    SortIcons,
    ConfirmDialogue
  },
  computed: {
    selectAll: {
      get: function () {
        return this.audits
          ? this.auditsCheckbox.length == this.audits.length
          : false;
      },
      set: function (value) {
        var auditsCheckbox = [];
        if (value) {
          this.audits.forEach(function (data) {
            auditsCheckbox.push(data.id);
          });
        }
        this.auditsCheckbox = auditsCheckbox;
      },
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getAuditLog();
    },
  },
  async beforeMount() {
    const userToken = localStorage.getItem("userToken");
    
    if (!userToken) {
      return this.tokenExpired();
    }

    try {
      const { user } = await this.$store.dispatch("auth/getAuthUser");
      const allPermission = user?.roles?.all_permission || [];

      // Check for specific permission
      this.bulk_delete_permission = allPermission.includes("bulk-delete-audit-logs");
    } catch (err) {
      console.error("Error fetching user permissions:", err);
    }
  },
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
    };
    if (localStorage.getItem("userToken")) {
      var _this = this;
      _this.getAuditLog();
    } else {
      this.router.push({ name: "login" });
    }
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getAuditLog();
    },
    getProductsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.auditsCheckbox = [];
      this.getAuditLog();
    },
    
    getAuditLog() {
      var _this = this;
      if (localStorage.getItem("userToken")) {
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        _this.isLoader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/audit-logs-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };

        _this
          .axios(config)
          .then(({ data }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.audits = decodedJson.audits;
            _this.data_length = decodedJson.counts;
            _this.auditsCheckbox = [];
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            if(response.status==500){
              _this.$toast.error(response.data.error, {
                position: "top-right",
                duration: 5000,
              });
            }  
            if (response.data.type == false) {
            _this.$router.push({ name: "login" });
            }         
          });
      } else {
        _this.tokenExpired();
      }
    },

    async deleteBulkAudits() {
      // Check if there are selected audits
      if (!this.auditsCheckbox.length) {
        this.getAuditLog();
        return;
      }

       
      // Show confirmation dialogue
      const confirmDelete = await this.$refs.confirmDialogue.show({
        title: "Delete Request",
        message: "Are you sure you want to delete the selected audit(s)?",
        okButton: "Delete",
      });

      if (!confirmDelete) return;

      // Ensure the user is authenticated
      const token = localStorage.getItem("userToken");
      if (!token) {
        this.tokenExpired();
        return;
      }

      // Update auditsCheckboxArr
      this.auditsCheckboxArr.checkboxes = this.auditsCheckbox;
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;

      // Prepare the request configuration
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/api/client-bulk-audit-delete`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { request_data: this.encodeAPIRequest(this.auditsCheckboxArr) },
      };

      try {
        // Make the API request
        const { data } = await this.axios(config);

        if (data.success) {
          this.$toast.success(data.message, { position: "top-right" });
          this.auditsCheckbox = [];
          this.selectAll = false;
          this.getAuditLog();
        } else {
          this.$toast.error(data.message, { position: "top-right" });
        }
      } catch (error) {
        const { response } = error;
        this.$toast.error(response?.data?.error || "An error occurred", {
          position: "top-right",
          duration: 5000,
        });

        // Redirect to login if unauthorized
        if (response?.status === 401) {
          this.$router.push({ name: "login" });
        }
      } finally {
        // Hide full page loader
        this.$parent.$refs.fullpageloader.is_full_page_loader = false;
      }
    },
  },
};
</script>